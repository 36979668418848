<template>
  <div style="margin-top: 10px; height: calc(100% - 10px)">
    <RouterKit
      :contentList="contentList"
      title="安全管理"
      iconName="icon-anquanguanli"
      @change="handleChangePath"
    >
      <template v-slot:form>
        <Form
          :groupCount="groupCountList"
          @change="hadnleChangeForm"
          @export="handleExport"
          :isExport="componentName == 'statistics'"
          style="margin-bottom: 10px"
        />
      </template>
      <template v-slot:route>
        <keep-alive>
          <component
            :is="componentName"
            :isIntersection="isIntersection"
            :vehicleIds="vehicleIds"
            :groupIds="groupIds"
            :labelValue="labelValue"
            :optionTableData="tableData"
            ref="componentRef"
            :option="option"
          />
        </keep-alive>
      </template>
    </RouterKit>
  </div>
</template>

<script>
import RouterKit from "@/components/RouterKit/index";
import Form from "@/components/RouterKit/components/Form";
import NoPermission from "@/components/RouterKit/components/NoPermission.vue";
import { groupCount, groupCountForAlarm } from "@/api/getData";
import statistics from "@/views/safetyManagement/components/statistics";
import analyze from "@/views/safetyManagement/components/analyze";
import {
  securityAlarmStatistics,
  securityAlarmStatisticsBlob,
} from "@/api/getData";
export default {
  name: "safetyManagement",
  components: {
    RouterKit,
    Form,
    statistics,
    analyze,
    NoPermission,
  },
  data() {
    return {
      groupCountList: [],
      componentName: "statistics",
      contentList: [
        {
          path: "statistics",
          title: "数据统计",
          iconName: "icon-shujutongji",
        },
        {
          path: "analyze",
          title: "数据分析",
          iconName: "icon-shujufenxi",
        },
        {
          path: "NoPermission",
          title: "数据管理",
          iconName: "icon-shujuguanli",
        },
      ],
      getData: {
        vehicleId: [],
        dateType: "W",
        startTime: "",
        endTime: "",
        groupId: [],
        labelValue: [],
        flag: 0,
      },
      vehicleIds: [],
      groupIds: [],
      labelValue: [],
      option: {},
      tableData: [],
      isIntersection: false,
    };
  },
  mounted() {
    this.init();
    // this.getAlarmStatistics();
  },
  methods: {
    async init() {
      // title   分类名称
      // list     分类数据数组
      // backName  返回字段名称
      let groupCountList = [];
      let group = await groupCount();

      if (group.flag == 1) {
        if (group.obj.stateCountData) {
          let num = 0;
          group.obj.stateCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.stateCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "设备状态",
            list: group.obj.stateCountData,
            backName: "stateCount", //选择后回调字段
          });
        }
        if (group.obj.productLineCountData) {
          let num = 0;
          group.obj.productLineCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.productLineCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "产品线分类",
            list: group.obj.productLineCountData,
            backName: "product", //选择后回调字段
          });
        }
        if (group.obj.brandCountData) {
          let num = 0;
          group.obj.brandCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.brandCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "品牌分类",
            list: group.obj.brandCountData,
            backName: "brandCount", //选择后回调字段
          });
        }
      }
      let alarm = await groupCountForAlarm({});
      if (alarm.flag == 1) {
        if (alarm.obj.alarmLevelCountData) {
          let num = 0;
          alarm.obj.alarmLevelCountData.map((val) => {
            num += val.sumResult;
          });
          alarm.obj.alarmLevelCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "报警等级分类",
            list: alarm.obj.alarmLevelCountData,
            backName: "alarmLevel", //选择后回调字段
          });
        }
        if (alarm.obj.alarmTypeCountData) {
          let num = 0;
          alarm.obj.alarmTypeCountData.map((val) => {
            num += val.sumResult;
          });
          alarm.obj.alarmTypeCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "报警类别",
            list: alarm.obj.alarmTypeCountData,
            backName: "alarm", //选择后回调字段
          });
        }
      }
      this.groupCountList = groupCountList;
    },
    async getAlarmStatistics() {
      securityAlarmStatistics(this.getData).then((res) => {
        console.log(res);
        if (res.flag == 1) {
          this.option = res.obj.weekDate;
          this.tableData =
            res.obj && res.obj.securityList ? res.obj.securityList : [];
          this.$message({
            type: "success",
            duration: 1000,
            message: "安全统计获取成功",
          });
        }
        this.componentName = "statistics";
      });
    },
    handleChangePath(val) {
      this.componentName = val;
    },
    hadnleChangeForm(val) {
      this.vehicleIds = val.vehicleIds ? val.vehicleIds.split(",") : [];
      this.groupIds = val.groupIds ? (val.groupIds + "").split(",") : [];
      this.labelValue = val.labelValue ? val.labelValue.split(",") : [];
      this.isIntersection = val.isIntersection;
    },
    handleExport(e) {
      // if (this.$refs.componentRef.selectionList) {
      // if(this.$refs.componentRef.selectionList.length == 0){
      //   this.$message({
      //     message: '请选择需要导出的数据',
      //     type: 'warning'
      //   });
      //   return false;
      // }else {
      securityAlarmStatisticsBlob({
        vehicleId: e.vehicleIds ? e.vehicleIds.split(",") : [],
        labelValue: e.labelValue ? e.labelValue.split(",") : [],
        flag: e.isIntersection ? 1 : 0,
        dateType: this.$refs.componentRef.timeType,
        startTime: this.$refs.componentRef.startTime,
        endTime: this.$refs.componentRef.endTime,
        groupId: e.groupIds ? e.groupIds.split(",") : [],
        exportFlag: 1,
      }).then((res) => {
        if (res.flag == 1) {
          this.option = res.obj;
        }
      });
      // }
      // };
    },
  },
};
</script>

<style></style>
