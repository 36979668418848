<template>
  <div>
    <div class="statistics-box" v-loading="isRequest">
      <div class="title">
        <div class="name">安全报警数字统计</div>
        <div class="time">
          <el-button type="text" :class="{'ash': timeType != 'W' }" @click="handleChangeType('W')">本周</el-button>
          <el-button type="text" :class="{'ash': timeType != 'M' }" @click="handleChangeType('M')">本月</el-button>
          <el-button type="text" :class="{'ash': timeType != 'Y' }" @click="handleChangeType('Y')">本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker
              v-model="temporaryStart"
              type="date"
              style="width: 130px" :picker-options="pickerOptions"
              @change="hadnleChangeTime" value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker
              v-model="temporaryEnd"
              type="date"
              style="width: 130px" :picker-options="pickerOptions"
              @change="hadnleChangeTime" value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="lineBox" style="height: 410px; width: 100%"></div>
          <div class="num-box">
            <div>
              <span>{{ numData[0] }}次</span>
              <div>严重报警次数</div>
            </div>
            <div>
              <span>{{ numData[1] }}次</span>
              <div>一般报警次数</div>
            </div>
            <div>
              <span>{{ numData[2] }}次</span>
              <div>提示报警次数</div>
            </div>
          </div>
        </div>
        <div class="notes"></div>
      </div>
    </div>
    <div style="margin: 10px 0">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%" :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="序号" type="selection" width="55"> </el-table-column>
        <el-table-column label="序号" type="index" width="55" align="center"></el-table-column>
        <el-table-column label="日期" width="120">
          <template slot-scope="scope">{{ scope.row.dateTime }}</template>
        </el-table-column>
        <el-table-column prop="plateGroupName" label="车辆/车组" width="120">
        </el-table-column>
        <el-table-column prop="statue" label="设备状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span :class="[ scope.row.statue > 5 || scope.row.statue == '' ? 'NeverOnline' : '', colorData[scope.row.statue] ]">{{ statueNameList[scope.row.statue] || '从未上线' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="报警等级" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="sumResult" label="报警次数" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.sumResult }}次
          </template>
        </el-table-column>
        <el-table-column prop="labelType" label="报警类别" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="productLine" label="产品线" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="brandName" label="品牌" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="loadingMore" v-if="securityList.length && securityList.length > 0" @click="DelayAssignList">点击加载更多</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { securityAlarmStatistics } from "@/api/getData"
export default {
  name: "alarmStatistics",
  data() {
    return {
      startTime: "",
      endTime: "",
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
      numData: {
        0: 0,
        1: 0,
        2: 0
      },
      temporaryStart: "",
      temporaryEnd: "",
      timeType: "W",
      tableData: [],
      securityList: [],
      statueNameList: ['怠速', '行驶', '作业', '通电', '熄火', '离线', '从未上线'],
      colorData: {
        0: 'yellow',
        1: 'NeverOnline',
        2: 'Online',
        3: 'blue',
        4: 'Offline',
        5: 'NeverOnline',
      },
      echartsData: {},
      selectionList: [],
      requestNum: 0,
      isRequest: 0,
      lineBox: null
    };
  },
  props: {
    option: {
      type: Object,
      default: function() {
        return {}
      }
    },
    optionTableData: {
      type: Array,
      default: function() {
        return []
      }
    },
    vehicleIds: {
      type: Array,
      default: function() {
        return [];
      }
    },
    groupIds: {
      type: Array,
      default: function() {
        return [];
      }
    },
    labelValue: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isIntersection: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    vehicleIds: {
      handler(){
        if (!this.requestNum) {
          this.requestNum += 1;
          this.getAlarmStatistics();
        }
      },
      deep: true
    },
    groupIds: {
      handler(){
        if (!this.requestNum) {
          this.requestNum += 1;
          this.getAlarmStatistics();
        }
      },
      deep: true
    },
    labelValue: {
      handler(){
        if (!this.requestNum) {
          this.requestNum += 1;
          this.getAlarmStatistics();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.echartsData = this.option;
    this.tableData = this.optionTableData;
    this.getWorkData();
  },
  methods: {
    async getAlarmStatistics() {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.isRequest) {
        return false
      }
      this.securityList = [];
      this.tableData = [];
      this.isRequest += 1;
      await securityAlarmStatistics({
        vehicleId: this.vehicleIds,
        flag: this.isIntersection ? 1 : 0,
        dateType: this.timeType,
        startTime: this.startTime,
        endTime: this.endTime,
        groupId: this.groupIds,
        labelValue: this.labelValue
      }).then(res=>{
        this.isRequest = 0;
        if (res.flag == 1) {
          this.echartsData = res.obj.weekDate;
          if(res.obj && res.obj.securityList) {
            this.securityList = res.obj.securityList;
            this.DelayAssignList();
          }
          // this.tableData = (res.obj && res.obj.securityList) ? res.obj.securityList : [];
          this.$message({
            type: "success",
            duration: 1000,
            message: "安全统计获取成功",
          });
        };
      }).catch(err=>{
        this.isRequest = 0;
      });
      this.requestNum = 0;
      this.getWorkData();
    },
    async getWorkData() {
      //   const res = await this.$API.findYearData(this.query);
      let { numData, xData, series } = await this.processPostData();
      this.numData = numData;
      let op = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          left: 'right',
          top: '45%',
          orient: 'vertical',
          data: ["严重报警", "一般报警", "提示报警"],
        },
        grid: {
          left: "3%",
          top: "12%",
          right: "150",
          bottom: "12%",
          containLabel: true,
        },
        xAxis: [
          {
            bottom: "5%",
            type: "category",
            name: "日期",
            nameGap: 5,
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#cccccc",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333", //更改坐标轴文字颜色
                // fontSize: w2      //更改坐标轴文字大小
              },
            },
            splitNumber: 2,
            maxInterval: 25,
            minInterval: 25,
            data: xData,
          },
        ],
        yAxis: [
          {
            name: "次数/次",
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#cccccc",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333", //更改坐标轴文字颜色
                // fontSize: w2      //更改坐标轴文字大小
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EFEFEF",
              },
            },
          },
        ],
        color: ["#FF4343", "#FFBB33", "#FFE03A"],
        dataZoom: [
          {
            type: "slider",
            show: true,
            handleSize: 5,
            height: 12,
            startValue: 0,
            endValue: 6,
            filterMode: "filter",
          },
          {
            type: "inside",
            xAxisIndex: [0],
            startValue: 0,
            endValue: 6,
          },
        ],
        series: [
          {
            name: "严重报警",
            type: "bar",
            barGap: "0%",
            data: series[0],
          },
          {
            name: "一般报警",
            type: "bar",
            data: series[1],
          },
          {
            name: "提示报警",
            type: "bar",
            data: series[2],
          },
        ],
      };
      this.lineBox ? this.lineBox.clear() : this.lineBox = echarts.init(this.$refs.lineBox, null, {});
      this.lineBox.setOption(op);
    },
    handleSelectionChange(val){
      this.selectionList = val;
    },
    tableRowClassName({row, rowIndex}) {
      // "check-row"
      return "";
    },
    handleChangeType(val) {
      this.timeType = val;
      this.startTime = "";
      this.endTime = "";
      this.getAlarmStatistics();
    },
    hadnleChangeTime(val) {
      if (this.temporaryStart && !this.temporaryEnd) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      }else if (!this.temporaryStart && this.temporaryEnd) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      }else if (this.temporaryStart && this.temporaryEnd && (new Date(this.temporaryStart) > new Date(this.temporaryEnd))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }else {
        this.startTime = this.temporaryStart;
        this.endTime = this.temporaryEnd;
        this.getAlarmStatistics();
      }
    },
    processPostData() {
      let numData = {
        0: 0,
        1: 0,
        2: 0
      }
      let xData = [];
      let series = {
        0: [],
        1: [],
        2: []
      };
      for (const key in this.echartsData) {
        xData.push(key)
        if (this.echartsData[key]) {
          if (this.echartsData[key]['严重报警']) {
            numData[0] += this.echartsData[key]['严重报警'];
            series[0].push(this.echartsData[key]['严重报警']);
          }else {
            series[0].push(0);
          }
          if (this.echartsData[key]['一般报警']) {
            numData[1] += this.echartsData[key]['一般报警'];
            series[1].push(this.echartsData[key]['一般报警']);
          }else {
            series[1].push(0);
          }
          if (this.echartsData[key]['提示报警']) {
            numData[2] += this.echartsData[key]['提示报警'];
            series[2].push(this.echartsData[key]['提示报警']);
          }else {
            series[2].push(0);
          }
        }else {
            series[0].push(0);
            series[1].push(0);
            series[2].push(0);
        }
      };
      return { numData, xData, series }
    },
    DelayAssignList(){
      if (this.securityList.length > 0) {
        this.tableData.push(...this.securityList.splice(0,10));
        // this.listTime = setTimeout(()=>{
        //   clearTimeout(this.listTime);
        //   this.listTime = null;
        //     this.DelayAssignList();
        // }, 1200)
      }else {
        this.$message.errer('列表加载完成!');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.statistics-box {
  background-color: #fff;
  padding-bottom: 38px ;
  > .title {
    display: flex;
    justify-content: space-between;
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px dashed #cccccc;
    > .name {
      position: relative;
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #666666;
    }
    > .name::after {
      content: "";
      position: absolute;
      left: 0px;
      top: 15px;
      height: 16px;
      width: 4px;
      background-color: rgba(66, 120, 201, 1);
    }
    > .time {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #666666;
      >.ash {
        color: #666666;
      }
    }
  }
  > .echarts-box {
    display: flex;
    margin-top: 16px;
    > .echarts {
      flex: 1;
      > .num-box {
        display: flex;
        justify-content: space-between;
        width: 350px;
        margin: auto;
        margin-top: 20px;
        > div {
          text-align: center;
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
          > span {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    > .notes {
      width: 25%;
    }
  }
}
.check-row {
  background-color: rgba(220, 232, 252, 1);
}
.loadingMore {
  padding: 12px;
  text-align: center;
  color: #409eff;
  cursor: pointer;
  background-color: #fff;
}
.loadingMore:hover {
  color: #409eff;
}
::v-deep .el-input--small .el-input__inner {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
</style>