<template>
  <div>
    <div class="statistics-box"
      v-loading="isRequestPie">
      <div class="title">
        <div class="name">安全报警占比分析</div>
        <div class="time">
          <el-button type="text"
            :class="{ ash: pieTimeType != 'W' }"
            @click="handleChangeType('W', 'setPieEcharts')">本周</el-button>
          <el-button type="text"
            :class="{ ash: pieTimeType != 'M' }"
            @click="handleChangeType('M', 'setPieEcharts')">本月</el-button>
          <el-button type="text"
            :class="{ ash: pieTimeType != 'Y' }"
            @click="handleChangeType('Y', 'setPieEcharts')">本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker v-model="pieStartTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setPieEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker v-model="pieEndTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setPieEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="lineBox"
            style="height: 410px; width: 100%"></div>
          <!-- <div class="num-box">
            <div>
              <span>50次</span>
              <div>严重报警次数</div>
            </div>
            <div>
              <span>50次</span>
              <div>一般报警次数</div>
            </div>
            <div>
              <span>50次</span>
              <div>提示报警次数</div>
            </div>
          </div> -->
        </div>
        <div class="notes"></div>
      </div>
    </div>
    <div class="statistics-box"
      v-loading="isRequestLine"
      style="margin: 10px 0">
      <div class="title">安全报警走势分析
        <div class="name"></div>
        <div class="time">
          <el-button type="text"
            :class="{ ash: lineTimeType != 'W' }"
            @click="handleChangeType('W','setLineEcharts')">本周</el-button>
          <el-button type="text"
            :class="{ ash: lineTimeType != 'M' }"
            @click="handleChangeType('M','setLineEcharts')">本月</el-button>
          <el-button type="text"
            :class="{ ash: lineTimeType != 'Y' }"
            @click="handleChangeType('Y','setLineEcharts')">本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker v-model="lineStartTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setLineEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker v-model="lineEndTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setLineEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="statisticsLine"
            style="height: 440px; width: 100%"></div>
          <!-- <div class="num-box">
            <div>
              <span>50次</span>
              <div>严重报警次数</div>
            </div>
            <div>
              <span>50次</span>
              <div>一般报警次数</div>
            </div>
            <div>
              <span>50次</span>
              <div>提示报警次数</div>
            </div>
          </div> -->
        </div>
        <div class="notes"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { securityAlarmStatistics } from "@/api/getData";
export default {
  name: "alarmAnalyze",
  data() {
    return {
      startTime: "",
      endTime: "",
      pieStartTime: "",
      pieEndTime: "",
      lineStartTime: "",
      lineEndTime: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pieTimeType: "W",
      lineTimeType: "W",
      tableData: [],
      requestNum: 0,
      isRequestPie: 0,
      isRequestLine: 0
    };
  },
  props: {
    option: {
      type: Object,
      default: function () {
        return {};
      },
    },
    vehicleIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
    groupIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
    labelValue: {
      type: Array,
      default: function () {
        return [];
      }
    },
    isIntersection: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    vehicleIds: {
      handler(val) {
        if (!this.requestNum) {
          this.requestNum += 1;
          this.getAlarmStatistics('setPieEcharts');
          this.getAlarmStatistics('setLineEcharts');
        }
      },
      // immediate: true,
      deep: true
    },
    groupIds: {
      handler(val) {
        if (!this.requestNum) {
          this.requestNum += 1;
          this.getAlarmStatistics('setPieEcharts');
          this.getAlarmStatistics('setLineEcharts');
        }
      },
      // immediate: true,
      deep: true
    },
    labelValue: {
      handler() {
        if (!this.requestNum) {
          this.requestNum += 1;
          this.getAlarmStatistics('setPieEcharts');
          this.getAlarmStatistics('setLineEcharts');
        }
      },
      deep: true
    }
  },
  mounted() {
    this.echartsData = this.option;
    this.getWorkData();
  },
  methods: {
    async getAlarmStatistics(type) {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (type == 'setPieEcharts' && this.isRequestPie || type == 'setLineEcharts' && this.isRequestLine) {
        return false;
      }
      type == 'setPieEcharts' ? this.isRequestPie += 1 : this.isRequestLine += 1;
      await securityAlarmStatistics({
        vehicleId: this.vehicleIds,
        flag: this.isIntersection ? 1 : 0,
        dateType: type == 'setPieEcharts' ? this.pieTimeType : this.lineTimeType,
        startTime: this.startTime,
        endTime: this.endTime,
        groupId: this.groupIds,
        labelValue: this.labelValue
      }).then(res => {
        type == 'setPieEcharts' ? this.isRequestPie -= 1 : this.isRequestLine -= 1;
        if (res.flag == 1) {
          this.echartsData = res.obj.weekDate;
          if (type == 'setPieEcharts') {
            this.$message({
              type: "success",
              duration: 1000,
              message: "安全占比获取成功",
            });
          } else {
            this.$message({
              type: "success",
              duration: 1000,
              message: "安全走势获取成功",
            });
          }

        };
      }).catch(err => {
        type == 'setPieEcharts' ? this.isRequestPie -= 1 : this.isRequestLine -= 1;
      });
      this.requestNum = 0;
      this.getWorkData(type);
    },
    async getWorkData(type) {
      let { numData, xData, series } = await this.processPostData();
      if (type) {
        this[type](numData, xData, series);
      } else {
        this.setPieEcharts(numData, xData, series);
        this.setLineEcharts(numData, xData, series);
      }
    },
    setPieEcharts(numData, xData, series) {
      let oData = [
        {
          value: numData[0],
          name: "严重报警",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FF4343",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: "#FF4343"
          }
        },
        {
          value: numData[1],
          name: "一般报警",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FFBB33",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: "#FFBB33"
          }
        },
        {
          value: numData[2],
          name: "提示报警",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FFE03A",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: "#FFE03A"
          }
        },
      ];
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        legend: {
          left: 'right',
          top: '45%',
          orient: 'vertical',
          data: ["严重报警", "一般报警", "提示报警"],
        },
        grid: {
          left: "3%",
          top: "12%",
          right: "150",
          bottom: "12%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "45%"],
            selectedMode: "single",
            data: oData.filter(val => val.value),
            label: {
              distanceToLabelLine: 0,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      let lineBox = echarts.init(this.$refs.lineBox, null, {});
      lineBox.setOption(option);
    },
    setLineEcharts(numData, xData, series) {
      const legendData = ["严重报警", "一般报警", "提示报警"];
      let option = {
        grid: {
          //  left: 20,
          left: '3%',
          right: '150',
          bottom: '28px',
          top: '26px',
          containLabel: true
        },
        legend: {
          bottom: '0px',
          orient: 'horizontal',
        },
        xAxis: {
          name: '日期',
          type: 'category',
          data: xData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#cccccc"
            }
          },
          splitLine: {
            show: false,
            //   lineStyle: {
            //       type: "dashed",
            //       color: "#EFEFEF"
            //   }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#333333', //更改坐标轴文字颜色
              // fontSize: w2      //更改坐标轴文字大小
            }
          },
          nameTextStyle: {
            fontSize: 8      //更改坐标轴文字大小
          },
        },
        yAxis: {
          type: 'value',
          name: '次数/次',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#333333',  //更改坐标轴文字颜色
              // fontSize: w2      //更改坐标轴文字大小
            }
          },
          nameTextStyle: {
            fontSize: 8      //更改坐标轴文字大小
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: '#cccccc'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#EFEFEF"
            }
          }

        },
        series: [
          {
            data: series[0],
            type: 'line',
            smooth: true,
            name: '严重报警',
            itemStyle: {
              normal: {
                color: '#FF4343', //折点颜色
                lineStyle: {
                  color: '#FF4343' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 67, 67, 0.3)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          },
          {
            data: series[1],
            type: 'line',
            name: '一般报警',
            smooth: true,
            itemStyle: {
              normal: {
                color: '#FFBB33', //折点颜色
                lineStyle: {
                  color: '#FFBB33' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 187, 51, 0.3)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 230, 97, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          },
          {
            data: series[2],
            type: 'line',
            smooth: true,
            name: '提示报警',
            itemStyle: {
              normal: {
                color: '#FFE661', //折点颜色
                lineStyle: {
                  color: '#FFE661' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 230, 97, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {}
          },
          formatter: function (params) {
            var res = params[0].axisValue + '<br/>';
            params.map((val, ind) => {
              res += params[ind].marker + legendData[ind] + '     ' + params[ind].value + '<br/>';
            });
            return res;
          }
        },
      };
      let statisticsLine = echarts.init(this.$refs.statisticsLine, null, {});
      statisticsLine.setOption(option);
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    tableRowClassName({ row, rowIndex }) {
      // "check-row"
      return "";
    },
    handleChangeType(val, type) {
      this.startTime = "";
      this.endTime = "";
      type == 'setPieEcharts' ? this.pieTimeType = val : this.lineTimeType = val;
      this.getAlarmStatistics(type);
    },
    hadnleChangeTime(val) {
      let startTime = "";
      let endTime = "";
      if (val == 'setPieEcharts') {
        startTime = this.pieStartTime;
        endTime = this.pieEndTime;
      } else {
        startTime = this.lineStartTime;
        endTime = this.lineEndTime;
      }
      if (startTime && !endTime) {
        this.$message({
          message: "请选择结束时间！",
          type: "warning",
        });
        return false;
      } else if (!startTime && endTime) {
        this.$message({
          message: "请选择开始时间！",
          type: "warning",
        });
        return false;
      } else if (
        startTime &&
        endTime &&
        new Date(startTime) > new Date(endTime)
      ) {
        this.$message({
          message: "请选择正确的开始时间和结束时间！",
          type: "warning",
        });
        return false;
      } else {
        this.startTime = startTime;
        this.endTime = endTime;
        this.getAlarmStatistics(val);
      }
    },
    processPostData() {
      let numData = {
        0: 0,
        1: 0,
        2: 0,
      };
      let xData = [];
      let series = {
        0: [],
        1: [],
        2: [],
      };
      for (const key in this.echartsData) {
        xData.push(key);

        if (this.echartsData[key]) {
          if (this.echartsData[key]["严重报警"]) {
            numData[0] += this.echartsData[key]["严重报警"];
            series[0].push(this.echartsData[key]["严重报警"]);
          } else {
            series[0].push(0);
          }
          if (this.echartsData[key]["一般报警"]) {
            numData[1] += this.echartsData[key]["一般报警"];
            series[1].push(this.echartsData[key]["一般报警"]);
          } else {
            series[1].push(0);
          }
          if (this.echartsData[key]["提示报警"]) {
            numData[2] += this.echartsData[key]["提示报警"];
            series[2].push(this.echartsData[key]["提示报警"]);
          } else {
            series[2].push(0);
          }
        } else {
          series[0].push(0);
          series[1].push(0);
          series[2].push(0);
        }
      }
      return { numData, xData, series };
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-box {
  background-color: #fff;
  padding-bottom: 38px;
  > .title {
    display: flex;
    justify-content: space-between;
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px dashed #cccccc;
    > .name {
      position: relative;
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #666666;
    }
    > .name::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 15px;
      height: 16px;
      width: 4px;
      background-color: rgba(66, 120, 201, 1);
    }
    > .time {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #666666;
      > .ash {
        color: #666666;
      }
    }
  }
  > .echarts-box {
    display: flex;
    margin-top: 16px;
    > .echarts {
      flex: 1;
      > .num-box {
        display: flex;
        justify-content: space-between;
        width: 350px;
        margin: auto;
        > div {
          text-align: center;
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
          > span {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    > .notes {
      width: 25%;
    }
  }
}
.check-row {
  background-color: rgba(220, 232, 252, 1);
}
::v-deep .el-input--small .el-input__inner {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
</style>